import { Component, OnInit } from '@angular/core';
import { urls } from '../../../../shared/services/constant';
import { AppService } from '../../../../shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-request',
  templateUrl: './notification-request.component.html',
  styleUrls: ['./notification-request.component.scss']
})
export class NotificationRequestComponent implements OnInit {
  public skip: number = 0;
  public limit: number = 100;
  public notificationRequestList: any = [];

  constructor(private router: Router,private appService: AppService,public translate: TranslateService,
    private toastr: ToastrService) { window.scroll(0,0)}

  ngOnInit() {
    this.appService.get(urls.getPendingRequestsList + "/" + this.skip + "/" + this.limit)
      .subscribe(
        (response: any) => {
          if (response.result != null && response.result.requests.length > 0) {
            this.notificationRequestList = response.result.requests;
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  acceptRejectRequest(reponseType: string, requestData: any) {
    let params = {
      "request_id": requestData._id,
      "senderId": requestData.senderId,
      "request_status": reponseType
    };
    this.appService.post(urls.acceptRejectRequest, params)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            this.appService.showSuccess(response.result.message);
            this.router.navigate(["/user/parents"]);
          }
          else {
            this.appService.showError(response.error.msg);
          }
          this.notificationRequestList = this.notificationRequestList.filter(item => item._id !== requestData._id);
        },
        (error) => {
          console.log(error);
        }
      )
  }
}
