import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { urls } from '../../../../shared/services/constant';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-distribute-rp',
  templateUrl: './distribute-rp.component.html',
  styleUrls: ['./distribute-rp.component.scss']
})
export class DistributeRpComponent implements OnInit {
  public totalCoin: number = null;
  public reward: number = null;
  public childList: Array<any> = []
  public userId: any = ''
  public sttaus: boolean = false;
  constructor(
    private appService: AppService,public translate: TranslateService,
    private router: Router) { }

  ngOnInit() {
    window.scroll(0,0)
    this.getTotal();
    this.getChild();
  }

  getTotal() {
    this.appService.post(urls.getTotalPoint)
      .subscribe((res: any) => {
        this.totalCoin = res.result.Total_Coin;
      })
  }

  getChild() {
    this.appService.get(urls.getChildList)
      .subscribe((res: any) => {
        this.childList = res.result.users
      })
  }

  checkReward() {
    if ((this.reward > this.totalCoin) || (this.reward <= 0)) {
      this.reward = null;
      this.sttaus = true;
    }
    else if (this.reward < this.totalCoin) {
      this.sttaus = false;
    }
    else {
      console.log(this.sttaus);
    }
  }

  submitReward() {
    if (this.userId.length && this.reward) {
      let payload = {
        point: this.reward,
        user_id: this.userId
      }
      this.appService.post(urls.distributePoint, payload)
        .subscribe((res: any) => {
          if (res.sc == 1) {
            this.router.navigate(['wallet']);
          }
        })
    }
  }

  allowCharacters(e: any): any {
    if ((e.charCode >= 48 && e.charCode <= 57))
    {
      return true;
    } else {
      return false;
    }
  }

}
