import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../../shared/services/app.service';
import { AuthServiceService } from '../../../../core/services/authService/auth-service.service';
import { PopupService } from '../../../../core/services/popup.service';
import { ApiResponse, ApiParams } from '../../../../shared/model/apiResponse';
import { urls,defaultLocation } from '../../../../shared/services/constant';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Options } from '@angular-slider/ngx-slider';
// import Auth from '@aws-amplify/auth';
// import { Analytics } from 'aws-amplify';
import { FormBuilder, FormGroup ,FormControl, FormArray} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
// const amplifyConfig = {
//   Auth: {
//     identityPoolId: 'us-east-1:280abb4f-8eea-4a15-a64c-fb313fcc326e',
//     region: 'us-east-1'
//   }
// }

//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
//   AWSPinpoint: {
//     appId: '2e2afc99918a42f9bf32ea1e8b0a164c',
//     region: 'us-east-1',
//     mandatorySignIn: false,
//   }
// }

// Analytics.configure(analyticsConfig)

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public programArr: any = [];
    public isUserLoggedIn = true;
    public indexvariable = 0;
    public showLoader: boolean;
    public navigationFrom: any;
    public firstTime:any;
    public config: SwiperConfigInterface;
    public modal: any;
    public userRole:any;
    public lat:any;
    public long:any;
    public showFiler: boolean = false;
    public faIconClass: string = 'fa fa-search';
    mapFilterForm: FormGroup;
    public hideProgramUl: boolean = false;
    public showNoResultFound: boolean = false;
    public searchArray: any = [];
    public apiParam: ApiParams = new ApiParams();
    public apiResponse: ApiResponse = new ApiResponse();
    public currentRolee: any;
    public locationList: any = [];
    public mod: any;
    public locIndex: any;
    public filterCount:number = 1;
    public filtercount:string = "1";
    public currentLocation: string = "Current Location";
    public bucketArray: any = [];
    public selSeason:string = "";
    public options: Options = {
        floor: 0,
        ceil: 100,
        showSelectionBarFromValue: 0
    };
    public value: number = 10;
    public radius: any = 10;
    public checkedBucket: any = [];
    public checkedProgram: any = [];
    public checkedDays: any = [];
    public seasonData: any = '';
    public keywordsArr = [];
    public keywords: any = [];
    public premium_type: any = 2;
    public showMsg:boolean = false;
    public skip = 1;
    public skipS = 1;
    public limit = 10;
    public timeout: any = null;
    public searchKey: string = '';
    public show: boolean = false;
    public mode: any;
    public programArray: any = [];
    public filtered:boolean = false;
    public page_no: number = 1;
    public season: any;
    public springSeason: string = '';
    public winterSeason: string = '';
    public fallSeason: string = '';
    public summerSeason: string = '';
    public isNull:any;
    public gradeArray:any; 
    public btn: any;
    public keyType:any;
    public bucketType:any;
    public viewLoading: boolean;
    public skipK = 1;
    public bucketKeywords:any=[];
    public seprateBucket: any=[];
    programArrayAsArray: any[] = [];
    selectedArrayBuckets: any = [];
    selectedCategoryFilter: boolean = false;
    // @HostListener('window:scroll', ['$event']) getScrollHeight() {
    //     let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    //     let max = document.documentElement.scrollHeight;
    //     let callMax = (max/9);
    //     let posMax = (pos/9)
    //     console.log(max, callMax, "callmax")
    //     if (posMax == callMax) {
    //        if(this.filtered == true && this.programArray.length == this.limit){
    //            this.page_no = this.page_no + 1;
    //            this.getProgramFilteredList(this.page_no)
    //        }
    //     }
    //  }
    constructor(private router: Router, private formBuilder: FormBuilder,
        private appService: AppService,public translate:TranslateService,
        private popupService: PopupService) {
        window.scroll(0, 0)
        this.showLoader = false;
        window.scroll(0,0)
        if (screen.width < 993) {
            this.config = {
                slidesPerView: 1,
                pagination: false,
                keyboard: false,
                navigation: true,
            };
        } else {
            this.config = {
                slidesPerView: 2,
                pagination: false,
                keyboard: false,
                navigation: true,
            };
        }
    }

    ngOnInit() {
        // Analytics.record({ name: 'Home Visited' });
        this.lat = Number(localStorage.getItem('lat'));
        this.long = Number(localStorage.getItem('lng'));
        this.mapFilterForm = this.formBuilder.group({
            season: [''],
            bucketList: [''],
            pType: [true],
            searchProgram: [''],
            search: [''],
            filter_program_type: this.formBuilder.array([]),
            bucket: this.formBuilder.array([]),
            days: [''],
            daysArr: this.formBuilder.array([]),
            time: [''],
            radius: 10,
            location: ['current'],
        });
        this.programChange('1', true);
        this.programChange('2', true);
        this.isLoggedIn();
        this.firstTime = localStorage.getItem('firstTime');
        this.userRole = localStorage.getItem('userRole');
        if(this.firstTime == "true" && this.userRole ==2){
            this.openModal('giveEq')
        }
    }
    get f() {
        return this.mapFilterForm.controls;
    }
    checkNull(){
        const params = {
            'timestamp': 0,
            'limit': 5,
            'page_no': 1,
            premium_type: 2,
            program_type: 3,
            latitude: this.lat,
            longitude: this.long
        };
        this.showLoader = true;
        this.appService.post(urls.nullCheck, params).subscribe(
            (response: any) => {
                this.showLoader = false;
                if (response['result'] && response['result'].hasOwnProperty('isNull')) {
                    this.isNull = response['result'].isNull;
                    if(this.isNull == false){
                        this.getProgramList()
                    }
                    else{
                        this.programArr = response['result'].home;
                        this.gradeArray = response['result'].pit
                    }
                }
            },
            (error) => {
                this.showLoader = false;
                console.log(error);
            }
        );
    }
    mapFilter() {
        if (this.faIconClass === 'fa fa-search' && this.showFiler == false) {
            this.faIconClass = 'fa fa-times';
            if (this.bucketArray.length == 0) {
                this.showLoader = true;
                this.getBucketList();
                this.getKeywordList(this.skip, this.limit);
                this.getUserProfile();
            }
        } else {
            this.faIconClass = 'fa fa-search';
        }
        this.showFiler = !this.showFiler;
    }
    onSearchChange(data) {
        this.showNoResultFound = false;
        if (data.target.value.length >= 3) {
            this.searchProgram(data.target.value);
        } else {
            this.searchArray = [];
            this.hideProgramUl = false;
        }
    }
    clickEvent() {
        this.mode = document.getElementById("keydrop").classList.toggle("show");
    }
    getKeywordList(skip, limit) {
        let params = {};
        this.show = false;
        this.apiParam.appJson = true;
        skip = skip ? ((skip - 1) * 10) : skip;
        this.appService.get(urls.listKeyword + `?skip=${skip}&limit=${limit}`)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        if (response.result.keywords && response.result.keywords.length > 0) {
                            response.result.keywords.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.keyword,
                                    'id': ele._id,
                                };
                                this.keywordsArr.push(obj);
                                this.showLoader = false;
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    checkForKeywords(keyword: any) {
        if (this.keywords.length > 0) {
            for (let i = 0; i <= this.keywords.length - 1; i++) {
                if (this.keywords[i] == keyword.name) {
                    return true;
                }
            }
        }
    }
    change(event, name) {
        if (event.target.checked == true) {
            if(this.keywords.length<5){
                this.keywords.push(name)
                this.filterCount = this.filterCount + 1
                if(this.filterCount <= 10){
                    this.filtercount = this.filterCount.toString();
                  }
                  else if(this.filterCount > 10 && this.filterCount < 20){
                      this.filtercount = "10+"
                  }
                  else if(this.filterCount == 20){
                    this.filtercount = this.filterCount.toString();
                  }
                  else if(this.filterCount > 20){
                      this.filtercount = "20+"
                  }
            }
            else{
             event.target.checked = false;
             this.appService.showInfo('Only 5 keywords can be applied on filters')
            }      
        }
        else {
            const index = this.keywords.indexOf(name);
            this.keywords.splice(index, 1);
            this.filterCount = this.filterCount - 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }
    removeKeyword(name){
        const index = this.keywords.indexOf(name);
        this.keywords.splice(index, 1);
        this.filterCount = this.filterCount - 1
        if(this.filterCount <= 10){
            this.filtercount = this.filterCount.toString();
          }
          else if(this.filterCount > 10 && this.filterCount < 20){
              this.filtercount = "10+"
          }
          else if(this.filterCount == 20){
            this.filtercount = this.filterCount.toString();
          }
          else if(this.filterCount > 20){
              this.filtercount = "20+"
          }
    }
    loadAllOnScroll(event) {
        const elem = event.target;
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
            if (this.searchKey == '') {
                this.skip = this.skip + 1;
                this.getKeywordList(this.skip, this.limit);
            }
            else {
                this.skipS = this.skipS + 1;
                this.searchKeyword(this.skipS, this.limit, this.searchKey)
            }
        }
        else{

        }
    }
    searchKeyword(skip, limit, keyword) {
        let params = {};
        this.apiParam.appJson = true;
        skip = skip ? ((skip - 1) * 10) : skip;
        this.appService.get(urls.listKeyword + `?skip=${skip}&limit=${limit}&name=${keyword}`)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        this.show = false;
                        if (response.result.keywords && response.result.keywords.length > 0) {
                            response.result.keywords.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.keyword,
                                    'id': ele._id,
                                };
                                this.keywordsArr.push(obj);
                                this.showLoader = false;
                            });
                        }
                        else {
                            if (this.keywordsArr.length == 0) {
                                this.show = true;
                            }
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    onKey(event) {
        this.searchKey = event
        clearTimeout(this.timeout);
        this.keywordsArr = [];
        var $this = this;
        this.timeout = setTimeout(function () {
            if (event.keyCode != 13) {
                this.mode = document.getElementById("keydrop").classList.add("show");
                if (event.length > 2) {
                    this.skipS = 1;
                    this.limit = 10;
                    $this.searchKeyword(this.skipS, this.limit, event)
                }
                else {
                    this.skip = 1;
                    this.limit = 10;
                    $this.getKeywordList(this.skip, this.limit);
                }
            }
        }, 1000);
    }
    searchProgram(data) {
        this.hideProgramUl = true;
        this.apiParam.appJson = true;
        let params = {
            'keyword': data,
            'latitude': this.lat,
            'longitude': this.long,
            'page_no': 1,
            'limit': 15
        };
        this.appService.post(urls.programSearch, params)
            .subscribe(
                (response: any) => {
                    this.showNoResultFound = false;
                    if (response && response.sc === 1) {
                        this.searchArray = [];
                        if (response.result.program && response.result.program.length > 0) {
                            response.result.program.forEach((ele, ind) => {
                                let obj = {
                                    'name': ele.program_name,
                                    'id': ele._id,
                                };
                                this.searchArray.push(obj);
                            });
                        } else {
                            this.showNoResultFound = true;
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    onSelectedProgram(event) {
        this.programDetail(event.id);
    }
    getUserProfile() {
        if (this.isUserLoggedIn) {
            this.locationList = [];
            this.appService.get(urls.getProfile).subscribe(
                data => {
                    if (data['result'] && data['result'].user.role) {
                        this.currentRolee = data['result'].user.role;
                    }
                    if (data['result'] && data['result'].locations) {
                        let arr = data['result'].locations;
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i].address_type != 2) {
                                this.locationList.push(arr[i]);
                            }
                        }
                    }
                }, error => {
                    console.log('error', error);
                }
            )
        }
    }
    setLocation(value, ref: any) {
        this.locIndex = ref;
        if (value == "current") {
            this.currentLocation = 'Current Location'
            this.fetchLocation();
        }
        else {
            let obj = value;
            this.lat = obj.lat;
            this.long = obj.long;
            this.currentLocation = obj.address;
        }
        this.mod = document.getElementById("myDropdown").classList.remove("show");
    }
    fetchLocation() {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                position => {
                    let data = {
                        lat: position.coords.latitude,
                        long: position.coords.longitude
                    };
                    this.lat = JSON.stringify(data.lat);
                    this.long = JSON.stringify(data.long);
                },
                error => {
                    this.lat = defaultLocation.latitude;
                    this.long = defaultLocation.longitude;
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    }
    saveLocations() {
        if (!this.isUserLoggedIn) {
            this.getPopup('onboarding');
            this.popupVisibilityChange();
        }
        else {
            this.getPopup('addLocations');
            this.popupVisibilityChange();
        }
    }
    popupVisibilityChange() {
        return this.popupService.popupVisibilityChange.subscribe(value => {
            if (this.popupService.isPopupVisible) {
                this.popupService.togglePopupVisibility(false);
                this.getUserProfile();
            }

        });
    }
    getBucketList() {
        let params = {};
        this.apiParam.appJson = true;
        this.appService.post(urls.listBucket, params)
            .subscribe(
                (response: any) => {
                    if (response && response.sc === 1) {
                        this.bucketArray = [];
                        if (response.result.bucket && response.result.bucket.length > 0) {
                            response.result.bucket.forEach((ele, ind) => {
                                let obj = {
                                    'icon': ele.icon,
                                    'image': ele.image,
                                    'name': ele.name,
                                    'id': ele._id,
                                };
                                this.bucketArray.push(obj);
                                this.showLoader = false;
                            });
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    bucketChange(btype: string, isChecked: boolean) {
        const bucketFormArray = <FormArray>this.mapFilterForm.controls.bucket;
        if (isChecked) {
            bucketFormArray.push(new FormControl(btype));
            this.selectedArrayBuckets = [];
            bucketFormArray.controls.forEach((control: FormControl) => {
              this.selectedArrayBuckets.push(control.value);
            });
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
        else {
            let index = bucketFormArray.controls.findIndex(x => x.value == btype)
            bucketFormArray.removeAt(index);
            this.selectedArrayBuckets = [];
            bucketFormArray.controls.forEach((control: FormControl) => {
                this.selectedArrayBuckets.push(control.value);
            });
            this.filterCount = this.filterCount - 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
        if(this.selectedArrayBuckets.length > 0){
            this.selectedCategoryFilter = true;
            console.log(this.selectedCategoryFilter, "this.selectedCategoryFilter")
        } else{
            this.selectedCategoryFilter = false;
            console.log(this.selectedCategoryFilter, "this.selectedCategoryFilter")
        }
    }
    programChange(ptype: string, isChecked: boolean) {
        const programFormArray = <FormArray>this.mapFilterForm.controls.filter_program_type;

        if (isChecked) {
            programFormArray.push(new FormControl(ptype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
              this.filtercount = this.filterCount.toString();
            }
            else if(this.filterCount > 10 && this.filterCount < 20){
                this.filtercount = "10+"
            }
            else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
            else if(this.filterCount > 20){
                this.filtercount = "20+"
            }
        } else {
            let index = programFormArray.controls.findIndex(x => x.value === ptype);
            programFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1;
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }
    dayChange(dtype: any, isChecked: boolean) {
        const dayFormArray = <FormArray>this.mapFilterForm.controls.daysArr;
        if (isChecked) {
            dayFormArray.push(new FormControl(dtype));
            this.filterCount = this.filterCount + 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        } else {
            let index = dayFormArray.controls.findIndex(x => x.value === dtype);
            dayFormArray.removeAt(index);
            this.filterCount = this.filterCount - 1
            if(this.filterCount < 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
    }
    increase(season){
        if(this.selSeason == ""){
            this.selSeason = season
            this.filterCount = this.filterCount + 1
            if(this.filterCount <= 10){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 10 && this.filterCount < 20){
                  this.filtercount = "10+"
              }
              else if(this.filterCount == 20){
                this.filtercount = this.filterCount.toString();
              }
              else if(this.filterCount > 20){
                  this.filtercount = "20+"
              }
        }
        else{
            this.selSeason = season
        } 
    }
    clearFilter() {
        this.mapFilterForm = this.formBuilder.group({
            season: [''],
            bucketList: [''],
            pType: [true],
            searchProgram: [''],
            search: [''],
            filter_program_type: this.formBuilder.array([]),
            bucket: this.formBuilder.array([]),
            days: [''],
            daysArr: this.formBuilder.array([]),
            time: [''],
            location: ['current'],
            radius: 10
        });
        this.checkedBucket = '';
        this.checkedProgram = '';
        this.seasonData = '';
        this.keywords = [];
        this.checkedDays = [];
        this.currentLocation = "Current Location";
        this.selSeason = "";
        this.fetchLocation();
        // this.mapFilter();
        if (this.bucketArray.length == 0) {
            this.showLoader = true;
            this.getBucketList();
            this.getKeywordList(this.skip, this.limit);
            this.getUserProfile();
        }
        this.filtercount = "3";
        this.filterCount = 3;
        this.filtered = false;
        this.getProgramList()
    }
    onSubmit(params) {
        this.showLoader = true;
        let data = Object.assign({}, params);
        this.checkedDays = data.daysArr;
        this.checkedBucket = data.bucket;
        this.checkedProgram = data.filter_program_type;
        if (this.checkedProgram.length > 1 || this.checkedProgram[0] === '2') {
            this.premium_type = 2;
        } else {
            this.premium_type = 1;
        }
        this.seasonData = data.season;
        this.programArray = [];
        this.radius = this.value;
        if(this.filterCount == 1 && this.value == 0){
            this.filtered = false;
            this.getProgramList();
        }
        else{
            this.filtered = true;
            this.getProgramFilteredList(this.page_no);
        }
    }
    opendropdown() {
        this.mod = document.getElementById("myDropdown").classList.toggle("show");
    }
    openModal(pop: any) {
        this.modal = document.getElementById(pop);
        this.modal.style.display = "block";
    }
    scrollKeyPop(event){
        const elem = event.target;
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
                this.skipK = this.skipK + 1;
                this.getBucketKey(this.skipK)
        }
        else{
          
        }
      }
    openBucket(pop:any,type,bucket){
        this.bucketKeywords = []
        this.modal = document.getElementById(pop);
        this.modal.style.display = "block";
        this.bucketType = bucket;
        this.keyType = type
        this.getBucketKey(this.skipK)
    }
    getBucketKey(skip){
       this.viewLoading = true;
       skip = skip ? ((skip - 1) * 10) : skip;
       let body = {
           grades : this.gradeArray,
           bucket_name:this.bucketType,
           type :this.keyType,
           skip : skip,
           limit: this.limit
       }
       this.appService.post(urls.getKey,body).subscribe((res:any)=>{
            if(res.sc == 1){
                if(res['result'].data && res['result'].data.length>0){
                    res['result'].data.forEach((ele, ind) => {
                        let obj = {
                            'name': ele.buckets,
                            'id': ele._id,
                            'keyword':ele.keyword,
                            'bookmark':ele.bookmark
                        };
                        this.bucketKeywords.push(obj);
                    })
                }
            }
            else{
                this.appService.showError(res['error'].err.msg)
              }
           this.viewLoading = false;
       })
    }
    addbookmark(id){
        this.viewLoading = true;
        this.appService.put(urls.addBuckBook + id,'').subscribe((res:any)=>{
            if(res.sc == 1){
              this.appService.showSuccess(res['result'].message);
              this.bucketKeywords = [];
              this.skipK = 1;
              this.getBucketKey(this.skipK)
            }
            else{
              this.appService.showError(res['error'].err.msg)
            }
            this.viewLoading = false;
        })
      }
      removebookmark(id){
        this.viewLoading = true;
        this.appService.delete(urls.addBuckBook + id,'').subscribe((res:any)=>{
            if(res.sc == 1){
              this.appService.showSuccess(res['result'].message);
              this.bucketKeywords = [];
              this.skipK = 1;
              this.getBucketKey(this.skipK)
            }
            else{
              this.appService.showError(res['error'].err.msg)
            }
            this.viewLoading = false;
        })
      }
    viewAllClick(id) {
        this.router.navigate(['/home/programlist'], { queryParams: { id: id } });
    }
    closemodal() {
        this.btn = document.getElementsByClassName("close")[0];
        this.modal.style.display = "none";
    }
    changeKeyValue(type){
        this.appService.post(urls.firstTime).subscribe((res:any)=>{
            if(res.sc==1){
                localStorage.setItem("firstTime","false")
                if(type == 'reject'){
                    this.modal.style.display = "none";
                }
                if(type == 'accept'){
                    this.router.navigate(["/personal-insight"])
                }
            }
        })
    }
    getProgramFilteredList(page) {
        this.apiParam.appJson = true;
        let params = {
            'bucket': this.checkedBucket,
            'filter_program_type': this.checkedProgram,
            'season': this.seasonData,
            'page': page,
            'page_size': 200,
        };
        params['latitude'] = this.lat;
        params['longitude'] = this.long;
        params['radius'] = this.radius;
        if (this.checkedDays && this.checkedDays.length) {
            params['daysOfWeek'] = this.checkedDays;
        }
        if(this.keywords && this.keywords.length){
            params['keywords'] = this.keywords
        }
        this.apiParam.params = params;
        this.apiParam.appJson = true;
        this.showLoader = true;
        this.appService.post(urls.listBylatlng, params).subscribe(
            (response: any) => {
                let ProgramData = response.result.program;
                        if (ProgramData && ProgramData.length > 0) {
                            ProgramData.forEach((ele, ind)  => {
                                if (ele.spring_season === 1) {
                                    this.springSeason = 'Spring, ';
                                }
                                if (ele.winter_season === 1) {
                                    this.winterSeason = 'Winter, ';
                                }
                                if (ele.fall_season === 1) {
                                    this.fallSeason = 'Fall, ';
                                }
                                if (ele.summer_season === 1) {
                                    this.summerSeason = 'Summer,';
                                }
                                this.season = this.springSeason + this.winterSeason + this.fallSeason + this.summerSeason;
                                this.season = this.season.slice(0, -1);

                                let obj = {
                                    'name': ele.program_name,
                                    'image': ele.image__url,
                                    'address': ele.address,
                                    'city':ele.city,
                                    'state':ele.state,
                                    'season': this.season,
                                    'agency': ele.agency_name,
                                    'id': ele._id,
                                    'favorite': ele.is_favourite,
                                    'program_type': ele.program_type,
                                    'free': ele.free,
                                    'buckets': ele.buckets
                                };
                                    this.programArray.push(obj);
                                    console.log(this.programArray, "this.programArra")
                                    const bucketItems: { [key: string]: { bucket: any, items: any[] } } = {};
                                    this.programArray.forEach(element => {
                                        element.buckets.forEach(bucket => {
                                            if (!bucketItems[bucket.name]) {
                                                bucketItems[bucket.name] = { bucket: bucket, items: [] };
                                            }
                                            bucketItems[bucket.name].items.push(element);
                                        });
                                    });
                                    this.programArrayAsArray = Object.values(bucketItems);
                                    console.log(this.programArrayAsArray, "this.programArrayAsArray")
                                    if(this.selectedCategoryFilter == true){
                                        const selectedIds = new Set(this.selectedArrayBuckets);
                                        console.log(this.selectedArrayBuckets, "this.selectedArrayBuckets");
                                        
                                        const existingBucketIds = new Set(this.programArrayAsArray.map(bucketGroup => bucketGroup.bucket._id));
                                        console.log(existingBucketIds, "existingBucketIds");
                                        this.programArrayAsArray = this.programArrayAsArray.filter(bucketGroup => {
                                            const existingId = bucketGroup.bucket._id;
                                            if (selectedIds.has(existingId)) {
                                                return true;
                                            }
                                            existingBucketIds.delete(existingId);
                                            return false;
                                        });
                                        
                                        console.log(this.programArrayAsArray, "Updated programArrayAsArray");
                                        console.log(existingBucketIds, "Updated existingBucketIds");
                                    } else{
                                        this.programArrayAsArray = Object.values(bucketItems);
                                    }
                                    

                            });

            }
            this.showLoader = false;
        },
            (error) => {
                this.showLoader = false;
                console.log(error);
            }
        );
    }
    getProgramList() {
        const params = {
            'timestamp': 0,
            'limit': 5,
            'page_no': 1,
            premium_type: 2,
            program_type: 3,
            latitude: this.lat,
            longitude: this.long
        };
        this.showLoader = true;
        this.appService.post(urls.programHomeList, params).subscribe(
            (response: any) => {
                this.showLoader = false;
                if (response['result'] && response['result'].home && response['result'].home.length) {
                    this.programArr = response['result'].home;
                }
            },
            (error) => {
                this.showLoader = false;
                console.log(error);
            }
        );
    }

    showSeason(ele) {
        if (ele) {
            let a = '';
            let b = '';
            let c = '';
            let d = '';
            if (ele.spring_season === 1) {
                a = 'Spring, ';
            }
            if (ele.winter_season === 1) {
                b = 'Winter, ';
            }
            if (ele.fall_season === 1) {
                c = 'Fall, ';
            }
            if (ele.summer_season === 1) {
                d = 'Summer,';
            }
            let season = '';
            season = a + b + c + d;
            if (d && d.length) {
                season = season.slice(0, -1);
            } else {
                season = season.slice(0, -2);
            }
            return season;
        }
    }

    isLoggedIn() {
        const user = localStorage.getItem('LoggedIn');
        if (user && user.length) {
            this.isUserLoggedIn = true;
            this.checkNull();
        } else {
            this.isUserLoggedIn = false;
            this.getProgramList()
        }
    }

    programDetail(id) {
        if (this.isUserLoggedIn) {
            this.navigationFrom = "home"
            this.router.navigate(['program/program-detail/' + id], { queryParams: { nav_from: this.navigationFrom } });
        } else {
            this.getPopup('onboarding');
            this.popupService.popupVisibilityChange.subscribe(value => {
                if (this.popupService.isPopupVisible) {
                    this.popupService.togglePopupVisibility(false);
                    this.router.navigate(['/']);
                }

            });
        }
    }

    getPopup(test): void {
        this.popupService.showPopup(test);
    }

    showBuckets(ele) {
        let buckets = '';
        for (let i = 0; i < ele.buckets.length; i++) {
            buckets = buckets + ele.buckets[i].name + ', ';
        }
        if (buckets && buckets.length) {
            buckets = buckets.slice(0, -2);
        }
        return buckets;
    }

    increaseIndexValue() {
        if (this.indexvariable < 3) {
            this.indexvariable = this.indexvariable + 1;
        }
    }

    decreaseIndexValue() {
        if (this.indexvariable > 0) {
            this.indexvariable = this.indexvariable - 1;
        }
    }

}
