import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from "../../../../shared/services/app.service";
import { PopupService } from "../../../../core/services/popup.service";
import { Router } from "@angular/router";
import { urls, toastMessage } from '../../../../shared/services/constant';
import { FormControl } from '@angular/forms';
import { DataService } from 'src/app/core/services/data/data.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'app-add-places',
  templateUrl: './add-places.component.html',
  styleUrls: ['./add-places.component.scss']
})
export class AddPlacesComponent implements OnInit {

  constructor(private appService: AppService,
    private popupService: PopupService,
    private router: Router,
    private dataService: DataService,public translate: TranslateService) { }
    public timeout: any = null;
    public search: string = '';
  public isAddButtonClicked: boolean = false;
  public homeString: string;
  public homeLocation: any;
  public addressId: any;
  public location1Address: string;
  public location2Address: string;
  public location3Address: string;
  public location1Location: any;
  public location2Location: any;
  public location3Location: any;
  public queryField: FormControl = new FormControl();
  public schoolList: any = [];
  public memberList:any = [];
  public stateList: any = [];
  public state : any =  0;
  public memstate : any =  0;
  public cityList: any = [];
  public city : any =  0;
  public memcity : any =  0;
  public school_name : string = '';
  public modal: any;
  public selectedSchool:any;
  public schoolListSorting = [];
  public schoolString: string = '';
  public schoolLocation: any;
  public memberLocation:any;
  public memberShipString: string = '';
  public memmodal: any;
  public path: any;
  public displayClnt: boolean = false;
  public isAddButtonClickedForHome: boolean = false;
  public isAddButtonClickedForSchool: boolean = false;
  public isAddButtonClickedForManualSchool : boolean = false;
  public isAddButtonClickedForMemberShip : boolean = false;
  public isAddButtonClickedForManualMembership : boolean = false;
  public isAddLocation1: boolean = false;
  public isAddLocation2: boolean = false;
  public isAddLocation3: boolean = false;
  public membership_email:any;
  public savedPlaceLocation: string = '';
  public body: any;
  public limit:number = 10;
  public viewLoading: boolean = false;
  public skip:number = 1;
  public subject: string;
  public toEmail: string;
  public dismainvalue:boolean = true;
  public disphomevalue:boolean = true;
  public disschoolvalue:boolean = true;
  public displocation1value:boolean = true;
  public displocation2value:boolean = true;
  public displocation3value:boolean = true;
  public dispmembershipvalue:boolean = true;
  public isHomeSchool:boolean = false;
  public homeSchool: object = {};
  public userSettings2: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  public locationSettings1: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  public locationSettings2: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  public locationSettings3: any = {
    inputPlaceholderText: 'Search Address',
    "showRecentSearch": false,
  };
  @ViewChild("placesRef",{static: false}) placesRef : GooglePlaceDirective;
  ngOnInit() {
    this.path = this.router.url;
    this.getStateList();
    if (this.path == '/user/saved-locations') {
      this.dataService.getMessage().subscribe(res => {
        if (res) {
          this.savedPlaceLocation = res.a;
          this.addressId = res.b
        }
      })
      if (this.path == '/user/saved-locations') {
        this.openModalBox(this.savedPlaceLocation);
      }
    }
  }
  getStateList(){
    this.viewLoading = true;
    this.appService.get(urls.schoolState).subscribe((response: any) => {
      if(response.sc == 1){
        if(response['result'] && response['result'].state){
          this.stateList = response['result'].state
        }
      }
      this.viewLoading  = false;
    })
  }
  getCityList(event){
    this.viewLoading = true;
    this.city = 0;
    this.memcity = 0;
    this.appService.get(urls.stateCities + `?state=${event.value}`).subscribe((res :any) =>{
      if(res.sc == 1){
        if(res['result'] && res['result'].city){
          this.cityList = res['result'].city;
          if(event.value == "0")
          {
            this.disschoolvalue = true;
            this.dispmembershipvalue = true;
          }
          else
          {
            this.disschoolvalue = false;
            this.dispmembershipvalue = false;
          }
        }
      }
      this.viewLoading  = false;
    })
  }
  getSchoolList() {
    this.viewLoading = true;
    this.appService.post(urls.listSchool + `?city=${this.city}&state=${this.state}`)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            if (response.result.schools && response.result.schools.length > 0) {
              this.homeSchool = response.result.homeSchool;
              this.schoolList = response.result.schools;
              this.schoolList = [this.homeSchool].concat(this.schoolList);
              this.isHomeSchool = response.result.isHomeSchool;
              console.log(this.homeSchool)
              if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
              {
                this.dismainvalue = false;
              }
              else
              {
                this.dismainvalue = true;
              }
            }
          }
          this.viewLoading  = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }
  getMemberShipList(skip) {
    this.viewLoading = true;
    skip = skip ? ((skip - 1) * 10) : skip;
    this.appService.get(urls.membershipList + `?city=${this.memcity}&state=${this.memstate}&skip=${skip}&limit=${this.limit}`)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            if (response.result.data && response.result.data.length > 0) {
              this.memberList = response.result.data;
            }
          }
          this.viewLoading  = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }
  onScroll(event){
    const elem = event.target;
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
              this.skip = this.skip + 1;
              this.getMemberShipList(this.skip);
        }

  }
  clickEvent() {
    this.modal = document.getElementById("myDropdown").classList.toggle("show");
    this.modal = document.getElementById('search').classList.toggle('active')
}
setSchool(school){
this.schoolString =school.insensitive;
this.modal = document.getElementById("myDropdown").classList.remove("show");
this.modal = document.getElementById('search').classList.remove('active')
}
onKey(event){
  this.search = event
  clearTimeout(this.timeout);
  this.schoolList = [];
  var $this = this;
  this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
          if (event.length > 2) {
              $this.searchSchool(event)
          }
          else {
              this.pageNo = 1;
              this.pageSize = 20;
              $this.getSchoolList();
          }
      }
  }, 1000);}
  searchSchool(event){
    this.viewLoading = true;
       this.appService.post(urls.listSchool + `?city=${this.city}&state=${this.state}&keyword=${event}`)
      .subscribe(
        (response: any) => {
          if (response && response.sc == 1) {
            if (response.result.schools && response.result.schools.length > 0) {
              this.schoolList = response.result.schools;
            }
          }
          this.viewLoading  = false;
        },
        (error) => {
          console.log(error);
        }
      )
  }
  //For Home Location
  searchNameForHome(address, searchLocationLat,searchLocationLon) {
    this.homeString = address;
    this.disphomevalue = false;
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    this.homeLocation = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallback1(selectedData: any) {
    console.log(selectedData.geometry.location.lat())
    // if (selectedData.response == true) {
      this.searchNameForHome(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    // }
    // else {
    //   this.appService.showError(toastMessage.invalidAddress);
    //   this.homeString = null;
    // }
  }
  detectChange(event:any){
    if(event.target.value.length == 0){
      this.disphomevalue = true;
    }
  }
  SaveLocationForHome() {
    if (this.homeString && this.path != '/user/saved-locations') {
      this.isAddButtonClickedForHome = false;
      this.isAddButtonClicked = false;
      if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
      {
        this.dismainvalue = false;
      }
      else
      {
        this.dismainvalue = true;
      }
    }
    else if (this.homeString && this.path == '/user/saved-locations' && this.addressId == '') {
      this.SaveMyPlace();
    }
    else if (this.homeString && this.path == '/user/saved-locations' && this.addressId != '') {

      this.editPlace(this.addressId);
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }

  //For School Location
  searchName(searchLocation) {
    this.schoolListSorting = [];
    for (let i = 0; i < this.schoolList.length; i++) {
      if (this.schoolList[i].insensitive.includes(searchLocation)) {
        this.schoolListSorting.push(this.schoolList[i]);
        this.displayClnt = true;
      }
    }
    if (searchLocation.length == 0) {
      this.schoolString = null;
    }
  }
  SaveLocationForSchool() {
    if (this.schoolString && this.path != '/user/saved-locations') {
      this.isAddButtonClickedForSchool = false;
      this.isAddButtonClicked = false;
      if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
      {
        this.dismainvalue = false;
      }
      else
      {
        this.dismainvalue = true;
      }
    }
    else if (this.schoolString && this.path == '/user/saved-locations' && this.addressId == '') {
      this.SaveMyPlace();
    }
    else if (this.schoolString && this.path == '/user/saved-locations' && this.addressId != '') {
      this.editPlace(this.addressId);
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }
  SaveLocationForMember() {
    if (this.memberShipString && this.memberLocation) {
      if(this.path == '/user/saved-locations'){
        this.sendMembershipRequest()
       }
       else{
        this.isAddButtonClickedForMemberShip = false;
        this.isAddButtonClicked = false;
        if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
        {
          this.dismainvalue = false;
        }
        else
        {
          this.dismainvalue = true;
        }
       }
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }
  getLocation(address, id) {
    this.schoolString = address;
    this.schoolLocation = id;
    if(this.schoolString)
    {
      this.disschoolvalue = false;  
    }
    else
    {
      this.disschoolvalue = true;  
    }
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    this.modal = document.getElementById("myDropdown").classList.remove("show");
    this.modal = document.getElementById('search').classList.remove('active')
  }
  clickMemEvent() {
    this.memmodal = document.getElementById("myDropdown").classList.toggle("show");
}
  getMemLocation(address,id){
    this.memberShipString = address;
    this.memberLocation = id;
    if(this.memberShipString)
    {
      this.dispmembershipvalue = false;
    }
    else
    {
      this.dispmembershipvalue = true;
    }
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    this.memmodal = document.getElementById("myDropdown").classList.remove("show");
  }
  //For 1st Location
  searchNameForFirstLocation(address, searchLocationLat,searchLocationLon) {
    this.location1Address = address;
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    this.displocation1value = false;
    this.location1Location = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallbackLocation1(selectedData: any) {
    console.log(selectedData)
    // if (selectedData.response == true) {
      this.searchNameForFirstLocation(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    // }
    // else {
    //   this.appService.showError(toastMessage.invalidAddress);
    //   this.location1Address = null;
    // }
  }
  SaveLocation1() {
    if (this.location1Address && this.path != '/user/saved-locations') {
      this.isAddLocation1 = false;
      this.isAddButtonClicked = false;
      if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
      {
        this.dismainvalue = false;
      }
      else
      {
        this.dismainvalue = true;
      }
    }
    else if (this.location1Address && this.path == '/user/saved-locations' && this.addressId == '') {
      this.SaveMyPlace();
    }
    else if (this.location1Address && this.path == '/user/saved-locations' && this.addressId != '') {
      this.editPlace(this.addressId);
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }

  //For 2nd Location
  searchNameForSecondLocation(address, searchLocationLat,searchLocationLon) {
    this.location2Address = address;
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    this.displocation2value = false;
    this.location2Location = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallbackLocation2(selectedData: any) {
    // if (selectedData.response == true) {
      this.searchNameForSecondLocation(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    // }
    // else {
    //   this.appService.showError(toastMessage.invalidAddress);
    //   this.location2Address = null;
    // }
  }
  SaveLocation2() {
    if (this.location2Address) {
      this.isAddLocation2 = false;
      this.isAddButtonClicked = false;
      if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
      {
        this.dismainvalue = false;
      }
      else
      {
        this.dismainvalue = true;
      }
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }

  //For 3rd Location
  searchNameForThirdLocation(address, searchLocationLat,searchLocationLon) {
    this.location3Address = address;
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    this.displocation3value = false;
    this.location3Location = {
      lat: searchLocationLat,
      lng: searchLocationLon
    };
  }
  autoCompleteCallbackLocation3(selectedData: any) {
    if (selectedData.response == true) {
      this.searchNameForThirdLocation(selectedData.formatted_address, selectedData.geometry.location.lat(),selectedData.geometry.location.lng());
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
      this.location3Address = null;
    }
  }
  SaveLocation3() {
    if (this.location3Address) {

      this.isAddLocation3 = false;
      this.isAddButtonClicked = false;
      if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
      {
        this.dismainvalue = false;
      }
      else
      {
        this.dismainvalue = true;
      }
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }

  // For Modal Heading
  openModalBox(name) {
    this.isAddButtonClicked = true;
    if (name == 'Home') {
      // if(this.homeString)
      // {
      //   this.disphomevalue = false;
      // }
      // else
      // {
      //   this.disphomevalue = true;
      // }
      this.isAddButtonClickedForHome = true;
      if (this.homeString) {
        this.userSettings2 = {
          "inputString": this.homeString
        };
      }
    }
    if (name == 'School') {
      if(this.schoolString)
      {
        this.disschoolvalue = false;
      }
      else
      {
        this.disschoolvalue = true;
      }
      this.isAddButtonClickedForSchool = true;
    }
    if(name == 'manualSchool'){
      this.isAddButtonClickedForSchool = false;
      this.city = 0;
      this.state = 0;
      this.isAddButtonClickedForManualSchool = true;
    }
    if (name == 'Membership') {
      this.isAddButtonClickedForMemberShip = true;
      if(this.memberShipString)
      {
        this.dispmembershipvalue = false;
      }
      else
      {
        this.dispmembershipvalue = true;
      }
    }
    if (name == 'Location1') {
      if(this.location1Address)
      {
        this.displocation1value = false;
      }
      else
      {
        this.displocation1value = true;
      }
      this.isAddLocation1 = true;
      if (this.location1Address) {
        this.locationSettings1 = {
          "inputString": this.location1Address
        };
      }
    }
    if (name == 'Location2') {
      if(this.location2Address)
      {
        this.displocation2value = false;
      }
      else
      {
        this.displocation2value = true;
      }
      this.isAddLocation2 = true;
      if (this.location2Address) {
        this.locationSettings2 = {
          "inputString": this.location2Address
        };
      }
    }
    if (name == 'Location3') {
      if(this.location3Address)
      {
        this.displocation3value = false;
      }
      else
      {
        this.displocation3value = true;
      }
      this.isAddLocation3 = true;
      if (this.location3Address) {
        this.locationSettings3 = {
          "inputString": this.location3Address
        };
      }
    }
  }

  backToAddPlaces() {
    this.isAddButtonClicked = false;
    this.isAddButtonClickedForHome = false;
    this.isAddButtonClickedForSchool = false;
    this.isAddButtonClickedForMemberShip = false;
    this.isAddLocation1 = false;
    this.isAddLocation2 = false;
    this.isAddLocation3 = false;
  }

  onSkip() {
    this.getPopup('linkSocialAccount');
  }

  getPopup(popupType): void {
    this.popupService.showPopup(popupType);
  }

  //Saving Data and Api Calling
  SaveMyPlaces() {
    if(this.homeString || this.schoolString || this.location1Address || this.location2Address || this.location3Address)
    {
      this.dismainvalue = false;
    }
    else
    {
      this.dismainvalue = true;
    }
    let address = [];
    if (this.homeString) {
      address.push({
        'address': this.homeString,
        'lat': this.homeLocation.lat,
        'long': this.homeLocation.lng,
        'address_type': '1'
      });
    }
    if (this.schoolString) {
      address.push({
        'address': this.schoolLocation,
        'address_type': '2'
      });
    }
    if (this.location1Address) {
      address.push({
        'address': this.location1Address,
        'lat': this.location1Location.lat,
        'long': this.location1Location.lng,
        'address_type': '3'
      });
    }
    if (this.location2Address) {
      address.push({
        'address': this.location2Address,
        'lat': this.location2Location.lat,
        'long': this.location2Location.lng,
        'address_type': '3'
      });
    }
    if (this.location3Address) {
      address.push({
        'address': this.location3Address,
        'lat': this.location3Location.lat,
        'long': this.location3Location.lng,
        'address_type': '3'
      });
    }
    if (address.length > 0) {
      if(this.memberLocation){
        this.sendMembershipRequest();
      }
      let data = {
        'addresses': address
      };
      this.appService.post(urls.addLocation, data)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.getPopup('linkSocialAccount');
            }
            else {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
    else {
      if(this.memberLocation){
        this.sendMembershipRequest()
      }
      else{
        this.appService.showError(toastMessage.invalidAddress);
      }
    }
  }
  sendMembershipRequest(){
    let data = {
      membership_id : this.memberLocation
    }
    this.appService.post(urls.membershipList,data)
    .subscribe(
      (response: any) => {
        if (response && response.sc == 1) {
           this.appService.showSuccess(response.result.message);
           if(this.path == '/user/saved-locations'){
            this.getPopup('');
            this.popupService.togglePopupVisibility(true);
           }
           else{
            this.getPopup('linkSocialAccount');
           }
        }
        else {
          this.appService.showError(response.error.msg);
          if(this.path == '/user/saved-locations'){
            this.getPopup('');
            this.popupService.togglePopupVisibility(true);
           }
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }
  SaveMyPlace() {
    let address = [];
    if (this.homeString) {
      address.push({
        'address': this.homeString,
        'lat': this.homeLocation.lat,
        'long': this.homeLocation.lng,
        'address_type': '1'
      });
    }
    if (this.schoolString) {
      address.push({
        'address': this.schoolLocation,
        'address_type': '2'
      });
    }
    if (this.location1Address) {
      address.push({
        'address': this.location1Address,
        'lat': this.location1Location.lat,
        'long': this.location1Location.lng,
        'address_type': '3'
      });
    }
    if (address.length > 0) {
      let data = {
        'addresses': address
      };
      this.appService.post(urls.addLocation, data)
        .subscribe(
          (response: any) => {
            if (response && response.sc == 1) {
              this.getPopup('');
              this.popupService.togglePopupVisibility(true);
            }
            else {
              this.appService.showError(response.error.msg);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    }
    else {
      this.appService.showError(toastMessage.invalidAddress);
    }
  }
  editPlace(id) {
    let address = [];
    if (this.homeString) {

      this.body = {
        location_id: id,
        address: this.homeString,
        lat: this.homeLocation.lat,
        long: this.homeLocation.lng,
        address_type: '1'
      }
    }
    if (this.schoolString) {

      this.body = {
        location_id: id,
        address: this.schoolLocation,
        address_type: '2'
      }
    }
    if (this.location1Address) {

      this.body = {
        location_id: id,
        address: this.location1Address,
        lat: this.location1Location.lat,
        long: this.location1Location.lng,
        address_type: '3'
      }
    }
    this.appService.post(urls.editLocation, this.body)
      .subscribe(
        (response: any) => {

          if (response && response.sc == 1) {
            this.getPopup('');
            this.popupService.togglePopupVisibility(true);

          }
          else {
            this.appService.showError(response.error.msg);
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }
  load(val) {
    if (val == this.router.url) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }
  }
  saveNewSchool(){
    let formData = {
      city: this.city,
      state: this.state,
      school_name: this.school_name
    }
    this.appService.post(urls.addNewSchool,formData).subscribe((res:any)=>{
         if(res.sc == 1){
            if(res['result']){
              this.appService.showSuccess(res['result'].message);
              this.getPopup('');
              this.popupService.togglePopupVisibility(true);
            }
         }
         else{
           this.appService.showError(res['error'].msg)
         }
    })
  }
  addhomeSchool() {
    let formData = {
      city: this.homeSchool['city'],
      state: this.homeSchool['state'],
      school_name: this.homeSchool['school_name'],
      status: 1
    }
    this.appService.post(urls.addNewSchool,formData).subscribe((res:any)=>{
         if(res.sc == 1){
            if(res['result']){
              this.appService.showSuccess(res['result'].message);
              this.getPopup('');
              this.popupService.togglePopupVisibility(true);
            }
         }
         else{
           this.appService.showError(res['error'].msg)
         }
    })
  }
  invite(){
          this.subject = "Invitation to spotivity"
          this.toEmail = ""
          let agencySignupLink = environment.agencySignupLink
          let content = `You have been invited to join our platform as Membership Organization.Please visit the link mentioned below and do checkout our platform.%0D%0A${agencySignupLink}`
          window.location.href = "mailto:" + this.toEmail + "?subject=" + this.subject + "&body=" + content;
  }
}
